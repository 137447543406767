import React from 'react';
import classnames from 'classnames';

import { IImage, ILink } from 'utils/types';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Tagline } from 'components/tagline/Tagline';
import { Button } from 'components/button/Button';
import { ArrowButton } from 'components/button/ArrowButton';
import { CoverVideo } from 'components/video/CoverVideo';

import s from './EpicHero.module.scss';

type Props = {
  index?: number;
  tagline?: string;
  title: string;
  text?: React.ReactNode;
  video?: ILink;
  image?: IImage;
  buttonLabel?: string;
  buttonLink?: ILink;
  children: React.ReactNode;
};

export const EpicHero = ({
  index,
  tagline,
  title,
  text,
  video,
  image,
  buttonLabel,
  buttonLink,
  children,
}: Props) => {
  const hasButton = !!buttonLink;

  return (
    <BackgroundSection color="image">
      <div className={s.hero}>
        <div className={s.hero__container}>
          <div className={s.hero__row}>
            <div className={s.hero__content}>
              {tagline && <Tagline index={index} text={tagline} />}
              <h1 className={s.hero__title}>{title}</h1>
              <div className={s.hero__text}>{text}</div>
              {buttonLink && (
                <div className={s.hero__button}>
                  <Button link={buttonLink}>{buttonLabel || 'Learn more'}</Button>
                </div>
              )}
            </div>
          </div>
          {!hasButton && (
            <div className={s.hero__scroller}>
              <ArrowButton tabIndex={-1} direction="down" color="white" scrollOnClick />
            </div>
          )}
          <div className={s.hero__ticker}>{children}</div>
        </div>

        <div className={classnames(s.hero__background, { [s.hasPortrait]: !!image?.portrait })}>
          {image?.landscape?.url && (
            <div
              className={s.hero__image}
              style={{ backgroundImage: `url(${image?.landscape.url})` }}
            />
          )}
          {image?.portrait?.url && (
            <div
              className={s.hero__imagePortrait}
              style={{ backgroundImage: `url(${image.portrait.url})` }}
            />
          )}
          {video?.href && (
            <CoverVideo
              className={s.hero__video}
              video={video.href}
              image={image?.landscape?.url ?? image?.url}
            />
          )}
        </div>
      </div>
    </BackgroundSection>
  );
};
