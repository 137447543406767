import React from 'react';
import classnames from 'classnames';

import s from './Tagline.module.scss';

type Props = {
  index?: number;
  size?: 'normal' | 'long';
  ordinal?: boolean;
  text?: React.ReactNode;
  line?: boolean;
  below?: boolean;
};

export const Tagline = ({
  index,
  size = 'normal',
  text,
  ordinal = false,
  line = true,
  below = false,
}: Props) => {
  const ordinalSuffix = (i) => {
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  };

  const paddedIndex = (i) => {
    return i.toString().padStart(2, 0);
  };

  return (
    <div
      className={classnames(s.tagline, {
        [s.long]: size === 'long',
        [s.line]: line,
        [s.below]: below,
      })}
    >
      {index !== undefined && (
        <div className={s.tagline__index}>
          {ordinal ? ordinalSuffix(index) : paddedIndex(index)}
        </div>
      )}
      <div className={s.tagline__text}>{text}</div>
    </div>
  );
};
