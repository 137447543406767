import React from 'react';
import { useUI } from 'utils/ui';

type Props = {
  image?: boolean;
  dark?: boolean;
  brand?: boolean;
  color?: 'white' | 'dark' | 'brand' | 'image' | string;
  className?: string;
  children: React.ReactNode;
};

export const BackgroundSection = ({ color = 'white', className, children }: Props) => {
  const el = React.useRef<HTMLDivElement>(null);
  const id = React.useId();
  const ui = useUI();
  const debug = false;
  const timer = React.useRef<any>();

  React.useEffect(() => {
    const calculate = () => {
      if (!el.current) {
        return;
      }

      const top = el.current.offsetTop;
      const bottom = el.current.offsetTop + el.current.offsetHeight;

      ui.registerSection({ color, top, bottom }, id);

      if (debug) {
        console.groupCollapsed(`Register section: ${id}`);
        console.log(`ID: ${id}`);
        console.log(`Color: ${color}`);
        console.log(`Size:`, top, bottom);
        console.groupEnd();
      }
    };

    const debounceCalc = (time = 50) =>
      new Promise((resolve) => {
        timer && clearTimeout(timer.current);

        timer.current = setTimeout(() => {
          calculate();
          resolve(true);
        }, time);
      });

    debounceCalc()
      .then(() => debounceCalc(200))
      .then(() => debounceCalc(500))
      .then(() => debounceCalc(1000));

    const handleResize = () => debounceCalc();

    window.addEventListener('resize', handleResize);

    return () => {
      timer && clearTimeout(timer.current);

      window.removeEventListener('resize', handleResize);

      ui.unregisterSection(id);
    };
  }, []);

  return (
    <div ref={el} className={className}>
      {children}
    </div>
  );
};
