import React from 'react';
import classnames from 'classnames';
import { IImage, ILink } from 'utils/types';
import { Link } from 'components/link/Link';

import s from './Ticker.module.scss';

type Props = {
  headline?: string;
  image?: IImage;
  link?: ILink;
  hideTitle?: boolean;
};

export const TickerItem = ({ headline, link, hideTitle }: Props) => {
  return (
    <Link
      className={classnames(s.tickerItem, { [s.hideTitle]: hideTitle })}
      tabIndex={-1}
      {...link}
    >
      <div className={s.tickerItem__image} />
      <div className={s.tickerItem__content}>{headline}</div>
    </Link>
  );
};
